import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const FaqsPage = () => (
    <Layout>
        <SEO
            title="Preguntas frecuentes - Aguaman"
            pathname="/preguntas-frecuentes"
            description="¿Llevan el botellón hasta mi casa?. Sí, al regístrarte en la aplicación te pedimos la dirección de entrega. ¿Cuanto tiempo demora la entrega? 30 minutos o menos."
        />
        <section className="faqs-prop">
            <div className="faqs-prop-content">
                <div className="faqs-title">
                    <h1>
                        <span role="img" aria-label="preguntas-frecuentes">
                            🤔
                        </span>{' '}
                        Preguntas frecuentes
                    </h1>
                    <hr />
                    <p style={{ fontStyle: 'italic' }}>
                        “Preguntar es vergüenza de un instante; no preguntar es vergüenza de una
                        vida.” <br />
                        <span style={{ fontStyle: 'normal', fontWeight: 'bold' }}>
                            {' '}
                            Haruki Murakami
                        </span>
                    </p>
                </div>
                <div className="faqs-content">
                    <h2>
                        <span role="img" aria-label="agua">
                            💦
                        </span>{' '}
                        Acerca del agua
                    </h2>
                    <h3>¿Es agua hervida?</h3>
                    <p>
                        No. Si bien hervir el agua es un método eficaz para matar virus y bacterias,
                        nosotros usamos <strong>agua purificada</strong> tratada bajo procesos
                        físicos.
                    </p>
                    <h3>¿De dónde proviene el agua del botellón?</h3>
                    <p>
                        El agua que entregamos en sus botellones es purificada bajo proceso físicos.
                        El proceso de purificación consiste es una serie de pasos al cual el agua se
                        somete para eliminar microorganismos y residuos a fin de obtener agua de
                        mayor pureza y calidad consumible.
                    </p>
                    <p>
                        El agua se obtiene de una vertiente natural (manantial, naciente de agua) el
                        cual es trasladado hasta una planta de purificación donde es sometida al
                        proceso descrito previamente. En resumen, el agua pasa por unos filtros
                        desbarradores, carbón activado, filtros suavizadores, luz ultravioleta e
                        inyección de ozono.
                    </p>
                    <h3>¿Cuanto tiempo puede durar el agua en el botellón?</h3>
                    <p>
                        Aconsejamos que el agua en el botellón no dure más de un (1) mes. El agua
                        que recibes ha sido embotellada máximo tres (3) días previos a la entrega.
                        Después que el botellón se destapa puede entrar pequeñas particulas que
                        hacen que el agua pierda calidad después del mes.
                    </p>
                    <br />
                    <br />

                    <h2>
                        <span role="img" aria-label="delivery">
                            🚚
                        </span>{' '}
                        Acerca de la entrega
                    </h2>
                    <h3>¿Llevan el botellón hasta mi casa?</h3>
                    <p>
                        Sí. Al regístrarte en la aplicación te pedimos una dirección de entrega.
                        Cuando te registras puedes omitir el paso de agregar la dirección y hacerlo
                        cuando vayas a Pedir una recarga de botellón.
                    </p>
                    <h3>¿Puedo pedir mi recarga de botellón a cualquier hora?</h3>
                    <p>
                        Sí. El pedido lo puedes hacer a cualquier hora del día pero la entrega se
                        hace entre las 08:00am - 05:00pm. Si tu botellón de agua está por acabarse,
                        puedes hacer el pedido la noche anterior y será entregado al día siguiente.
                    </p>
                    <h3>¿Cuanto tiempo demora la entrega?</h3>
                    <p>
                        30 minutos o menos.Te damos la opción de programar la entrega, es decir,
                        colocas la hora en que quieres recibir tu pedido.
                    </p>
                    <p>
                        También puedes calcular la hora en que tu botellón se va a acabar. Por
                        ejemplo, en el desayuno te das cuenta que queda poca agua en el botellón,
                        pide tu recarga para después del mediodia sabiendo que te consumirás el agua
                        restante durante el almuerzo.
                    </p>
                    <h3>¿Puedo cancelar mi pedido?</h3>
                    <p>
                        Sí. Puedes hacerlo hasta que tu pedido no haya sido enviado. Tienes una
                        opción en el detalle del pedido para Cancelar Pedido. Es útil cuando no vas
                        a estar en el domicilio a la hora de entrega. Después de cancelar el pedido,
                        puedes hacer otro pedido nuevamente.
                    </p>
                    <br />
                    <br />

                    <h2>Acerca del botellón</h2>
                    <h3>¿Ustedes se llevan el botellón y lo recargan?</h3>
                    <p>
                        No. Nosotros tenemos botellones propios, nuevos y usados, los cuales
                        recargamos previo al momento de la entrega del pedido. Cuando te damos el
                        botellón recargado nos das tu botellón vacío, el cual debe estar en buen
                        estado, es decir, sin huecos que permitan la fuga del agua.
                    </p>
                    <h3>No tengo botellón, ¿ustedes los venden?</h3>
                    <p>
                        Disponemos de botellones nuevos de excelente calidad, puedes pedirlos desde
                        la aplicación. Si compras un botellón a través de la app, sabremos que te
                        debemos llevar la recarga en un botellón de la misma calidad. De esta forma
                        tendrás siempre un botellón bueno y bonito.
                    </p>
                    <h3>Mi botellón está muy deteriorado</h3>
                    <p>
                        Te aconsejamos que adquieras uno nuevo, a través de nuestro programa de
                        referidos puedes acumular puntos y cambiar tu botellón por uno totalmente
                        nuevo.
                    </p>
                    <br />
                    <br />

                    <h2>
                        <span role="img" aria-label="app">
                            📱
                        </span>{' '}
                        Acerca de la app
                    </h2>
                    <h3>No me permite agregar mi dirección porque estoy fuera de la zona azul.</h3>
                    <p>
                        La aplicación está delimitada por zonas, si tu ubicación está cercana a la
                        zona azul escríbenos al número de contacto o por email para proveerte el
                        servicio.
                    </p>
                    <p>
                        Varios clientes han estado fuera de la zona azul, se comunican con nosotros
                        y con gusto le llevamos el botellón hasta su dirección de domicilio.
                    </p>
                </div>
            </div>
        </section>
    </Layout>
);

export default FaqsPage;
